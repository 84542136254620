import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_PROXIES = 'O365/PROXIES/SET_PROXIES';
const SET_PROXIES_POOL = 'O365/PROXIES/SET_PROXIES_POOL';

const PROXIES_ENDPOINT = `${O365}/proxies`;
const PROXIES_POOL_ENDPOINT = `${PROXIES_ENDPOINT}/proxypools`;

function initialState() {
  return {
    proxies: [],
    proxiesPool: [],
  };
}

const state = initialState();

// actions
const actions = {
  async GET_PROXIES({ commit }, organizationId) {
    const response = await axios.get(PROXIES_ENDPOINT, { params: { organizationId } });
    const { data } = response;
    if (data.success) {
      commit(SET_PROXIES, data.data);
    }
  },
  async GET_PROXIES_POOL({ commit }, companyId) {
    const response = await axios.get(PROXIES_POOL_ENDPOINT, { params: { companyId } });
    const { data } = response;
    if (data.success) {
      commit(SET_PROXIES_POOL, data.data);
    }
  }
};

const getters = {
  getProxies: state => state.proxies,
  getProxiesPool: state => state.proxiesPool,
};

const mutations = {
  [SET_PROXIES](state, data) {
    state.proxies = data;
  },
  [SET_PROXIES_POOL](state, data) {
    state.proxiesPool = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
