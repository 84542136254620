import axios from 'axios';
import { O365 } from '@/common/data/constants';

const SET_COMPANY = 'O365/PROVISIONING/SET_COMPANY';
const SET_USER_EMAIL = 'O365/PROVISIONING/SET_USER_EMAIL';

const PROVISIONING_ENDPOINT = `${O365}/provisioning`;
const VERIFY_EMAIL_ENDPOINT = `${PROVISIONING_ENDPOINT}/verify-email`;
const VALIDATE_RECAPTCHA_ENDPOINT = `${PROVISIONING_ENDPOINT}/validate-recaptcha`;
const GET_CHECKOUT_SESSION_ENDPOINT = `${PROVISIONING_ENDPOINT}/checkout-session`;
const GET_CHECKOUT_SESSION_RESULT_ENDPOINT = '/subscriptions/checkout/:checkoutId/success';
const UPDATE_PAYMENT_METHOD_ENDPOINT = '/subscriptions/checkout/update';
const RESEND_VERIFY_EMAIL_ENDPOINT = `${PROVISIONING_ENDPOINT}/resend-verify-email`;

function initialState() {
  return {
    company: '',
    userEmail: '',
  };
}

const state = initialState();

// actions
const actions = {
  POST_PROVISIONING(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(PROVISIONING_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data)
            reject(e.response.data);
          else reject('Error while registering. Try again later.');
        });
    });
  },
  O365_EMAIL_VERIFICATION({commit}, hash) {
    return new Promise((resolve, reject) => {
      axios
        .post(VERIFY_EMAIL_ENDPOINT, { hash })
        .then(response => {
          const { data } = response;
          if (data.success && data.redirect) {
            commit(SET_COMPANY, data.companyId);
            commit(SET_USER_EMAIL, data.email);
          }
          resolve(data);
        })
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while verifying the email. Try again later.');
          else reject('Error while verifying the email. Try again later.');
        });
    });
  },
  VALIDATE_RECAPTCHA(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(VALIDATE_RECAPTCHA_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while registering. Try again later.');
          else reject('Error while registering. Try again later.');
        });
    });
  },
  GET_CHECKOUT_SESSION(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(GET_CHECKOUT_SESSION_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while registering. Try again later.');
          else reject('Error while registering. Try again later.');
        });
    });
  },
  UPDATE_PAYMENT_METHOD(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(UPDATE_PAYMENT_METHOD_ENDPOINT, data)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while registering. Try again later.');
          else reject('Error while registering. Try again later.');
        });
    });
  },
  GET_CHECKOUT_SESSION_RESULT(context, data) {
    const url = GET_CHECKOUT_SESSION_RESULT_ENDPOINT.replace(':checkoutId', data);
    return new Promise((resolve, reject) => {
      axios
        .get(url)
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while registering. Try again later.');
          else reject('Error while registering. Try again later.');
        });
    });
  },
  RESEND_VALIDATION_EMAIL(context, email) {
    return new Promise((resolve, reject) => {
      axios
        .post(RESEND_VERIFY_EMAIL_ENDPOINT, {email})
        .then(response => resolve(response.data))
        .catch(e => {
          if (e.response && e.response.data && e.response.data.message)
            reject(e.response.data.message || 'Error while registering. Try again later.');
          else reject('Error while registering. Try again later.');
        });
    });
  },
  SET_PROVISIONING_COMPANY_AND_EMAIL ({commit}, params) {
    commit(SET_COMPANY, params.companyId);
    commit(SET_USER_EMAIL, params.email);
  }
};

const getters = {
  getO365ProvisioningCompany: state => state.company,
  getO365ProvisioningUserEmail: state => state.userEmail,
};

const mutations = {
  [SET_COMPANY](state, data) {
    state.company = data;
  },
  [SET_USER_EMAIL](state, data) {
    state.userEmail = data;
  },
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
