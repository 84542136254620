import axios from 'axios';
import {
  SET_ORG_STEP_ONE,
  SET_ORG_STEP_TWO,
  SET_ORG_STEP_THREE_BASIC,
  SET_ORG_STEP_FOUR_BASIC,
  SET_ORG_STEP_FOUR_MODERN,
  SET_ORG_STEP_FIVE_MODERN,
  SET_ORG_STEP_THREE_LEGACY,
  SET_ORG_STEP_FOUR_LEGACY,
  SET_ORG_REPO_STEP,
  RESET_ORG_WIZARD
} from './mutations-types';
import { O365 } from '@/common/data/constants';

const SET_IS_EDITING = 'O365/WIZARDS/ORGANIZATIONS/SET_IS_EDITING';
const SET_ORGANIZATION = 'O365/WIZARDS/ORGANIZATIONS/SET_ORGANIZATION';

const ORGANIZATIONS_ENDPOINT = `${O365}/organizations`;
const SINGLE_ORGANIZATION_ENDPOINT = `${ORGANIZATIONS_ENDPOINT}/:organizationId`;
const DEVICE_CODE_ENDPOINT = `${ORGANIZATIONS_ENDPOINT}/device-code`;

function initialState() {
  return {
    organizationWizard: {
      stepOne: {},
      stepTwo: {},
      stepThreeBasic: { exchangeOnline: {} },
      stepFourBasic: { sharePointOnline: {} },
      stepFourModern: { sharePointOnline: {}, exchangeOnline: {} },
      stepFiveModern: { deviceCode: null },
      stepThreeLegacy: { exchangeOnline: {} },
      stepFourLegacy: { sharePointOnline: {} },
      repoStep: {},
      isEditing: false
    },
    organization: {}
  };
}

const state = initialState();

// actions
const actions = {
  async ADD_ORGANIZATION({ state, commit }) {
    const data = getDataToSend(state.organizationWizard);
    const response = await axios.post(ORGANIZATIONS_ENDPOINT, data);
    commit(RESET_ORG_WIZARD);
    return response.data;
  },
  async DELETE_ORGANIZATION(context, organizationId) {
    const response = await axios.delete(
      SINGLE_ORGANIZATION_ENDPOINT.replace(':organizationId', organizationId)
    );
    // if(response.data.success) {
    // let companies = JSON.parse(JSON.stringify(getters.getCompanies));
    // const elementIndex = companies.data.findIndex(
    //   c => c.applications.office365.organizations.findIndex(o => o.id === organizationId) > -1
    // );
    // const company = companies.data[elementIndex];
    // const orgIdx = company.applications.office365.organizations.findIndex(
    //   o => o.id === organizationId
    // );
    // company.applications.office365.organizations[orgIdx].name = 'asdasd';
    // companies.data[elementIndex] = company;
    // commit('companies/SET_COMPANIES', companies);
    // }
    return response.data;
  },
  async GET_ORGANIZATION({ commit }, { organizationId, companyObjId, setWizard }) {
    const response = await axios.get(
      SINGLE_ORGANIZATION_ENDPOINT.replace(':organizationId', organizationId)
    );
    const { data } = response;
    if (data.success) {
      const org = data.data;
      if (setWizard) {
        commit(SET_ORG_STEP_ONE, { ...org, companyObjId });
        if (
          org.exchangeOnlineSettings?.applicationCertificateThumbprint ||
          org.sharePointOnlineSettings?.applicationCertificateThumbprint
        ) {
          const account =
            org.sharePointOnlineSettings?.account || org.exchangeOnlineSettings?.account;
          const applicationId =
            org.sharePointOnlineSettings?.applicationId ||
            org.exchangeOnlineSettings?.applicationId;
          commit(SET_ORG_STEP_TWO, { ...org, auth: 'modern' });
          commit(SET_ORG_STEP_FOUR_MODERN, {
            sharePointOnline: { ...org.sharePointOnlineSettings },
            account,
            applicationId
          });
        } else if (
          org.exchangeOnlineSettings?.applicationId ||
          org.sharePointOnlineSettings?.applicationId
        ) {
          commit(SET_ORG_STEP_TWO, { ...org, auth: 'modern', legacy: true });
          commit(SET_ORG_STEP_THREE_LEGACY, { exchangeOnline: { ...org.exchangeOnlineSettings } });
          commit(SET_ORG_STEP_FOUR_LEGACY, {
            sharePointOnline: { ...org.sharePointOnlineSettings }
          });
        } else {
          commit(SET_ORG_STEP_TWO, { ...org, auth: 'basic' });
          commit(SET_ORG_STEP_THREE_BASIC, { exchangeOnline: { ...org.exchangeOnlineSettings } });
          commit(SET_ORG_STEP_FOUR_BASIC, {
            sharePointOnline: { ...org.sharePointOnlineSettings }
          });
        }
        commit(SET_IS_EDITING, true);
      } else {
        commit(SET_ORGANIZATION, org);
        return org;
      }
    }
  },
  CLEAR_ORGANIZATION({ commit }) {
    commit(SET_ORGANIZATION, {});
  },
  async EDIT_ORGANIZATION({ state, commit }) {
    const data = getDataToSend(state.organizationWizard);
    const response = await axios.put(
      SINGLE_ORGANIZATION_ENDPOINT.replace(':organizationId', data.id),
      data
    );
    commit(RESET_ORG_WIZARD);
    return response.data;
  },
  async GET_DEVICE_CODE({ state }) {
    const { stepTwo, stepOne } = state.organizationWizard;
    const data = { targetRegion: stepTwo.region, companyObjId: stepOne.companyObjId };
    const response = await axios.post(DEVICE_CODE_ENDPOINT, data);
    return response.data;
  }
};

function getDataToSend(organizationWizard) {
  const {
    stepOne,
    stepTwo,
    stepThreeBasic,
    stepFourBasic,
    stepFourModern,
    stepFiveModern,
    stepThreeLegacy,
    stepFourLegacy,
    repoStep
  } = organizationWizard;
  const data = {
    ...stepOne,
    region: stepTwo.region
  };
  let exchangeOnlineSettings = {};
  let sharePointOnlineSettings = {};
  if (stepTwo.auth === 'basic') {
    data.authType = stepTwo.auth;
    exchangeOnlineSettings.account = stepThreeBasic.exchangeOnline.account;
    exchangeOnlineSettings.password = stepThreeBasic.exchangeOnline.password;
    exchangeOnlineSettings.grantAdminAccess = stepThreeBasic.exchangeOnline.grantAdminAccess;
    sharePointOnlineSettings.account = stepFourBasic.sharePointOnline.account;
    sharePointOnlineSettings.password = stepFourBasic.sharePointOnline.password;
    sharePointOnlineSettings.grantAdminAccess = stepFourBasic.sharePointOnline.grantAdminAccess;
  } else if (stepTwo.legacy) {
    data.authType = 'modernLegacy';
    exchangeOnlineSettings.account = stepThreeLegacy.exchangeOnline.account;
    exchangeOnlineSettings.password = stepThreeLegacy.exchangeOnline.password;
    exchangeOnlineSettings.applicationId = stepThreeLegacy.exchangeOnline.applicationId;
    exchangeOnlineSettings.applicationSecret = stepThreeLegacy.exchangeOnline.applicationSecret;
    exchangeOnlineSettings.grantAdminAccess = stepThreeLegacy.exchangeOnline.grantAdminAccess;
    sharePointOnlineSettings.account = stepFourLegacy.sharePointOnline.account;
    sharePointOnlineSettings.password = stepFourLegacy.sharePointOnline.password;
    sharePointOnlineSettings.applicationId = stepFourLegacy.sharePointOnline.applicationId;
    sharePointOnlineSettings.applicationSecret = stepFourLegacy.sharePointOnline.applicationSecret;
    sharePointOnlineSettings.grantAdminAccess = stepFourLegacy.sharePointOnline.grantAdminAccess;
  } else if (stepTwo.auth === 'modern') {
    data.authType = stepTwo.auth;
    data.newApplicationName = stepFourModern.newApplicationName;
    data.userCode = stepFiveModern.deviceCode;
    exchangeOnlineSettings.account = stepFourModern.exchangeOnline.account;
    exchangeOnlineSettings.applicationId = stepFourModern.exchangeOnline.applicationId;
    sharePointOnlineSettings.account = stepFourModern.exchangeOnline.account;
    sharePointOnlineSettings.sharePointSaveAllWebParts = true;
    sharePointOnlineSettings.applicationId = stepFourModern.sharePointOnline.applicationId;
  }
  data.exchangeOnlineSettings = exchangeOnlineSettings;
  data.sharePointOnlineSettings = sharePointOnlineSettings;
  data.repository = { ...repoStep };
  return data;
}

const getters = {
  getOrgWizStepOne: state => state.organizationWizard.stepOne,
  getOrgWizStepTwo: state => state.organizationWizard.stepTwo,
  getOrgWizStepThreeBasic: state => state.organizationWizard.stepThreeBasic,
  getOrgWizStepFourBasic: state => state.organizationWizard.stepFourBasic,
  getOrgWizStepFourModern: state => state.organizationWizard.stepFourModern,
  getOrgWizStepThreeLegacy: state => state.organizationWizard.stepThreeLegacy,
  getOrgWizStepFourLegacy: state => state.organizationWizard.stepFourLegacy,
  getOrgWizRepoStep: state => state.organizationWizard.repoStep,
  getOrgWizIsEditing: state => state.organizationWizard.isEditing,
  getOrganization: state => state.organization
};

const mutations = {
  /* eslint-disable no-undef */
  [SET_ORG_STEP_ONE](
    state,
    {
      id,
      type,
      isExchangeOnline,
      isSharePointOnline,
      isTeamsOnline,
      isTeamsChatsOnline,
      companyObjId,
      companyName,
      name
    }
  ) {
    Vue.set(state.organizationWizard.stepOne, 'id', id);
    Vue.set(state.organizationWizard.stepOne, 'type', type);
    Vue.set(state.organizationWizard.stepOne, 'isExchangeOnline', isExchangeOnline);
    Vue.set(state.organizationWizard.stepOne, 'isSharePointOnline', isSharePointOnline);
    Vue.set(state.organizationWizard.stepOne, 'isTeamsOnline', isTeamsOnline);
    Vue.set(state.organizationWizard.stepOne, 'isTeamsChatsOnline', isTeamsChatsOnline);
    Vue.set(state.organizationWizard.stepOne, 'companyObjId', companyObjId);
    Vue.set(state.organizationWizard.stepOne, 'companyName', companyName);
    Vue.set(state.organizationWizard.stepOne, 'name', name);
  },
  [SET_ORG_STEP_TWO](state, { region, auth, legacy }) {
    Vue.set(state.organizationWizard.stepTwo, 'region', region);
    Vue.set(state.organizationWizard.stepTwo, 'auth', auth);
    Vue.set(state.organizationWizard.stepTwo, 'legacy', legacy);
  },
  [SET_ORG_STEP_THREE_BASIC](state, { exchangeOnline, useSameCredentials }) {
    Vue.set(
      state.organizationWizard.stepThreeBasic.exchangeOnline,
      'account',
      exchangeOnline.account
    );
    Vue.set(
      state.organizationWizard.stepThreeBasic.exchangeOnline,
      'password',
      exchangeOnline.password
    );
    Vue.set(
      state.organizationWizard.stepThreeBasic.exchangeOnline,
      'grantAdminAccess',
      exchangeOnline.grantAdminAccess
    );
    Vue.set(state.organizationWizard.stepThreeBasic, 'useSameCredentials', useSameCredentials);
  },
  [SET_ORG_STEP_FOUR_BASIC](state, { sharePointOnline }) {
    Vue.set(
      state.organizationWizard.stepFourBasic.sharePointOnline,
      'account',
      sharePointOnline.account
    );
    Vue.set(
      state.organizationWizard.stepFourBasic.sharePointOnline,
      'password',
      sharePointOnline.password
    );
    Vue.set(
      state.organizationWizard.stepFourBasic.sharePointOnline,
      'grantAdminAccess',
      sharePointOnline.grantAdminAccess
    );
  },
  [SET_ORG_STEP_FOUR_MODERN](
    state,
    { sharePointOnline, newApplicationName, account, applicationId, configureApplication }
  ) {
    if (state.organizationWizard.stepOne.isSharePointOnline) {
      Vue.set(
        state.organizationWizard.stepFourModern.sharePointOnline,
        'sharePointSaveAllWebParts',
        sharePointOnline.sharePointSaveAllWebParts
      );
      Vue.set(
        state.organizationWizard.stepFourModern.sharePointOnline,
        'officeOrganizationName',
        sharePointOnline.officeOrganizationName
      );
      Vue.set(
        state.organizationWizard.stepFourModern.sharePointOnline,
        'applicationId',
        applicationId
      );
      Vue.set(state.organizationWizard.stepFourModern.sharePointOnline, 'account', account);
    }
    if (state.organizationWizard.stepOne.isExchangeOnline) {
      Vue.set(
        state.organizationWizard.stepFourModern.exchangeOnline,
        'applicationId',
        applicationId
      );
      Vue.set(state.organizationWizard.stepFourModern.exchangeOnline, 'account', account);
    }
    Vue.set(state.organizationWizard.stepFourModern, 'newApplicationName', newApplicationName);
    Vue.set(state.organizationWizard.stepFourModern, 'configureApplication', configureApplication);
  },
  [SET_ORG_STEP_FIVE_MODERN](state, { deviceCode }) {
    Vue.set(state.organizationWizard.stepFiveModern, 'deviceCode', deviceCode);
  },
  [SET_ORG_STEP_THREE_LEGACY](state, { exchangeOnline, useSameCredentials }) {
    Vue.set(
      state.organizationWizard.stepThreeLegacy.exchangeOnline,
      'applicationId',
      exchangeOnline.applicationId
    );
    Vue.set(
      state.organizationWizard.stepThreeLegacy.exchangeOnline,
      'applicationSecret',
      exchangeOnline.applicationSecret
    );
    Vue.set(
      state.organizationWizard.stepThreeLegacy.exchangeOnline,
      'account',
      exchangeOnline.account
    );
    Vue.set(
      state.organizationWizard.stepThreeLegacy.exchangeOnline,
      'password',
      exchangeOnline.password
    );
    Vue.set(
      state.organizationWizard.stepThreeLegacy.exchangeOnline,
      'grantAdminAccess',
      exchangeOnline.grantAdminAccess
    );
    Vue.set(state.organizationWizard.stepThreeLegacy, 'useSameCredentials', useSameCredentials);
  },
  [SET_ORG_STEP_FOUR_LEGACY](state, { sharePointOnline }) {
    Vue.set(
      state.organizationWizard.stepFourLegacy.sharePointOnline,
      'applicationId',
      sharePointOnline.applicationId
    );
    Vue.set(
      state.organizationWizard.stepFourLegacy.sharePointOnline,
      'applicationSecret',
      sharePointOnline.applicationSecret
    );
    Vue.set(
      state.organizationWizard.stepFourLegacy.sharePointOnline,
      'account',
      sharePointOnline.account
    );
    Vue.set(
      state.organizationWizard.stepFourLegacy.sharePointOnline,
      'password',
      sharePointOnline.password
    );
    Vue.set(
      state.organizationWizard.stepFourLegacy.sharePointOnline,
      'grantAdminAccess',
      sharePointOnline.grantAdminAccess
    );
  },
  [SET_ORG_REPO_STEP](state, { password, repeatPassword, passwordDescription, retentionPolicy }) {
    Vue.set(state.organizationWizard.repoStep, 'password', password);
    Vue.set(state.organizationWizard.repoStep, 'repeatPassword', repeatPassword);
    Vue.set(state.organizationWizard.repoStep, 'passwordDescription', passwordDescription);
    Vue.set(state.organizationWizard.repoStep, 'retentionPolicy', retentionPolicy);
  },
  [RESET_ORG_WIZARD](state) {
    Vue.set(state.organizationWizard, 'stepOne', {});
    Vue.set(state.organizationWizard, 'stepTwo', {});
    Vue.set(
      state.organizationWizard,
      'stepThreeBasic',
      initialState().organizationWizard.stepThreeBasic
    );
    Vue.set(
      state.organizationWizard,
      'stepFourBasic',
      initialState().organizationWizard.stepFourBasic
    );
    Vue.set(
      state.organizationWizard,
      'stepFourModern',
      initialState().organizationWizard.stepFourModern
    );
    Vue.set(
      state.organizationWizard,
      'stepFiveModern',
      initialState().organizationWizard.stepFiveModern
    );
    Vue.set(
      state.organizationWizard,
      'stepThreeLegacy',
      initialState().organizationWizard.stepThreeLegacy
    );
    Vue.set(
      state.organizationWizard,
      'stepFourLegacy',
      initialState().organizationWizard.stepFourLegacy
    );
    Vue.set(state.organizationWizard, 'repoStep', initialState().organizationWizard.repoStep);
    Vue.set(state.organizationWizard, 'isEditing', false);
  },
  [SET_IS_EDITING](state, data) {
    Vue.set(state.organizationWizard, 'isEditing', data);
  },
  [SET_ORGANIZATION](state, data) {
    state.organization = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
