import axios from 'axios';
import router from '../../router';
import { LOCAL_STORAGE_ITEMS } from '@/common/data/constants';

const SSO_CLICKS = 'sso_clicks';

// Mutation types
const LOG_IN = 'security/LOG_IN';
const LOG_OUT = 'security/LOG_OUT';
const GET_USER = 'user/GET_USER';
const UPDATE_USER = 'user/UPDATE_USER';
const VALIDATE_USERNAME = 'security/VALIDATE_USERNAME';
const USER_TOKEN_NAME = 'userToken';
const USER_INFO_NAME = 'userInfo';
const WIZARD_PROMPT_DISMISSED = 'wizardPromptDismissed';
const SET_ZERTO_SESSION_ID = 'user/SET_ZERTO_SESSION_ID';
const SET_WIZARD_PROMPT_DISMISSED = 'user/SET_WIZARD_PROMPT_DISMISSED';

// Errors
const CHANGE_PASSWORD_ERROR_MESSAGE = 'Sorry, change password failed.';
const CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE = 'The current password is not valid.';
const USERNAME_ALREADY_EXISTS_ERROR_MESSAGE = 'The username already exists.';
const UPDATE_USER_ERROR = 'Sorry, edit profile failed';
// const SINGLE_SIGN_ON_ERROR_MESSAGE = 'Single sign on failed.';
const INVALID_USERNAME_ERROR = 'Invalid username';
const INVALID_SECRET_QUESTIONS_ERROR = 'Invalid security answers';
const INVALID_RESET_PASSWORD_ERROR = 'Password has not been reseted. Please, try later.';
const INVALID_UNLOCK_ERROR = 'The account has not been unlocked. Please, try later';

const ENDPOINTS = {
  LOG_IN_AD: 'auth/login/ad',
  VERIFY_SMS_LOG_IN_AD: 'auth/login/ad/sms',
  SAVE_MOBILE_PHONE_SEND_SMS_AD: 'auth/login/ad/sms/phone',
  RESEND_SMS_AD: 'auth/login/ad/sms/resend',
  LOG_IN_DB: 'auth/login/db',
  VERIFY_SMS_LOG_IN_DB: 'auth/login/db/sms',
  SAVE_MOBILE_PHONE_SEND_SMS_DB: 'auth/login/db/sms/phone',
  RESEND_SMS_DB: 'auth/login/db/sms/resend',
  SINGLE_SIGN_ON: 'auth/login-token',
  VALIDATE_SSO: 'auth/validate-sso/{provider}',
  LOG_OUT: 'auth/logout',
  GET_USER: 'profile/{id}',
  CHANGE_PASSWORD_AD: 'profile/{id}/change-password/ad',
  CHANGE_PASSWORD_DB: 'profile/{id}/change-password/db',
  VALIDATE_USERNAME: 'auth/validate/{username}',
  VALIDATE_SECRET_QUESTIONS: 'auth/validate/secret-questions',
  UNLOCK_ACCOUNT: 'profile/unlock',
  RESET_PASSWORD: 'profile/reset-password',
  FORGOT_PASSWORD_EMAIL: 'profile/forgot-password/email',
  FORGOT_PASSWORD_CHANGE: 'profile/forgot-password/change',
  FORGOT_PASSWORD_CHECK: 'profile/forgot-password/check',
  LOGIN_AS: 'auth/login-as'
};

function initialState() {
  return {
    token: localStorage.getItem(USER_TOKEN_NAME) || null,
    user: JSON.parse(localStorage.getItem(USER_INFO_NAME)) || {},
    profile: {},
    unlock: { username: '', questions: [], veeamServiceAccount: false },
    isLoggedInAs: !!(
      localStorage.getItem('adminUserInfo') && localStorage.getItem('adminUserToken')
    ),
    wizardPromptDismissed: localStorage.getItem(WIZARD_PROMPT_DISMISSED)
  };
}

// initial state
const state = initialState();

function processLoginResponse(response) {
  let token = response.data.token;
  let user = {
    id: response.data.userId,
    username: response.data.username,
    accounts: response.data.accounts,
    email: response.data.email,
    name: response.data.name,
    acl: response.data.acl,
    passwordReset: response.data.passwordReset,
    veeamServiceAccount: response.data.veeamServiceAccount,
    theme: { ...response.data.theme },
    sso: { ...response.data.sso },
    forceSecurityQuestions: response.data.forceSecurityQuestions,
    mobilePhone: response.data.mobilePhone,
    officePhone: response.data.officePhone,
    managedFilterType: response.data.managedFilterType,
    assignAsConnectWiseOwner: response.data.assignAsConnectWiseOwner,
    serviceProviderId: response.data.serviceProviderId,
    masterServiceProviderId: response.data.masterServiceProviderId,
    isO365Migrator: response.data.isO365Migrator,
    features: response.data.features,
    landingApp: response.data.landingApp,
    ackO365TermsOfUse: response.data.ackO365TermsOfUse,
    hasOrganizations: response.data.hasOrganizations,
    hasJobs: response.data.hasJobs,
    wizardPromptDismissed: false
  };
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  localStorage.setItem(USER_TOKEN_NAME, token);
  localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
  return { user, token };
}

// actions
const actions = {
  SINGLE_SIGN_ON({ commit }, data) {
    let provider = data.provider;
    let clicks = { vac: 0, vcd: 0, zerto: 0 };
    if (localStorage.getItem(SSO_CLICKS)) {
      clicks = JSON.parse(localStorage.getItem(SSO_CLICKS));
    }
    return new Promise((resolve, reject) => {
      if (clicks[provider] === 0) {
        clicks[provider]++;
        localStorage.setItem(SSO_CLICKS, JSON.stringify(clicks));
        resolve({ data: { success: true } });
      } else {
        clicks[provider]++;
        localStorage.setItem(SSO_CLICKS, JSON.stringify(clicks));
        // Check if sessions are still valid
        axios
          .post(ENDPOINTS.VALIDATE_SSO.replace('{provider}', provider), data)
          .then(response => {
            if (provider === 'zerto') {
              commit(SET_ZERTO_SESSION_ID, response);
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  LOG_IN_AD({ commit }, credentials) {
    if (localStorage.getItem(WIZARD_PROMPT_DISMISSED)) {
      localStorage.removeItem(WIZARD_PROMPT_DISMISSED);
      commit(SET_WIZARD_PROMPT_DISMISSED, false);
    }
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.LOG_IN_AD, credentials)
        .then(response => {
          const { data } = response;
          if (data.token) {
            const processedLoginResponse = processLoginResponse(response);
            commit(LOG_IN, processedLoginResponse);
            resolve(processedLoginResponse.user);
          } else resolve(data);
        })
        .catch(error => {
          reject(error.response.data.message);
        });
    });
  },
  VERIFY_SMS_LOG_IN_AD(
    { commit },
    { username, password, smsCode, rememberComputer, userUpdatedPhoneOnce }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.VERIFY_SMS_LOG_IN_AD, {
          username,
          password,
          smsCode,
          rememberComputer,
          userUpdatedPhoneOnce
        })
        .then(response => {
          const { data } = response;
          if (data.token) {
            const processedLoginResponse = processLoginResponse(response);
            commit(LOG_IN, processedLoginResponse);
            resolve(processedLoginResponse.user);
          } else resolve(data);
        })
        .catch(error => reject(error.response.data.message));
    });
  },
  SAVE_MOBILE_PHONE_SEND_SMS_AD(context, { username, password, mobilePhone }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.SAVE_MOBILE_PHONE_SEND_SMS_AD, {
          username,
          password,
          mobilePhone
        })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data.message));
    });
  },
  RESEND_SMS_AD(context, { username, password, newMobilePhone }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.RESEND_SMS_AD, { username, password, newMobilePhone })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data.message));
    });
  },
  LOG_IN_DB({ commit }, credentials) {
    if (localStorage.getItem(WIZARD_PROMPT_DISMISSED)) {
      localStorage.removeItem(WIZARD_PROMPT_DISMISSED);
      commit(SET_WIZARD_PROMPT_DISMISSED, false);
    }
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.LOG_IN_DB, credentials)
        .then(response => {
          const { data } = response;
          if (data.token) {
            const processedLoginResponse = processLoginResponse(response);
            commit(LOG_IN, processedLoginResponse);
            resolve(processedLoginResponse.user);
          } else resolve(data);
        })
        .catch(error => reject(error.response.data));
    });
  },
  VERIFY_SMS_LOG_IN_DB(
    { commit },
    { username, password, smsCode, rememberComputer, userUpdatedPhoneOnce }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.VERIFY_SMS_LOG_IN_DB, {
          username,
          password,
          smsCode,
          rememberComputer,
          userUpdatedPhoneOnce
        })
        .then(response => {
          const { data } = response;
          if (data.token) {
            const processedLoginResponse = processLoginResponse(response);
            commit(LOG_IN, processedLoginResponse);
            resolve(processedLoginResponse.user);
          } else resolve(data);
        })
        .catch(error => reject(error.response.data.message));
    });
  },
  SAVE_MOBILE_PHONE_SEND_SMS_DB(context, { username, password, mobilePhone }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.SAVE_MOBILE_PHONE_SEND_SMS_DB, {
          username,
          password,
          mobilePhone
        })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data.message));
    });
  },
  RESEND_SMS_DB(context, { username, password, newMobilePhone }) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.RESEND_SMS_DB, { username, password, newMobilePhone })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data.message));
    });
  },
  LOG_OUT({ commit, dispatch }) {
    return new Promise(resolve => {
      localStorage.removeItem(USER_TOKEN_NAME);
      localStorage.removeItem(USER_INFO_NAME);
      localStorage.removeItem(SSO_CLICKS);
      localStorage.removeItem('adminUserInfo');
      localStorage.removeItem('adminUserToken');
      localStorage.removeItem('previousAdminPage');
      localStorage.removeItem(WIZARD_PROMPT_DISMISSED);
      commit(LOG_OUT, {});
      dispatch('CLEAR_O365_ORG_NAMES');
      resolve(true);
    });
  },
  GET_USER({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.GET_USER.replace('{id}', id))
        .then(response => {
          commit(GET_USER, response.data);
          resolve(response.data);
        })
        .catch(() => {
          reject(CHANGE_PASSWORD_ERROR_MESSAGE);
        });
    });
  },
  UPDATE_USER({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(ENDPOINTS.GET_USER.replace('{id}', data._id), data)
        .then(response => {
          commit(UPDATE_USER, response.data);
          resolve();
        })
        .catch(error => {
          if (error.response && error.response.status === 409)
            reject(USERNAME_ALREADY_EXISTS_ERROR_MESSAGE);
          reject(UPDATE_USER_ERROR);
        });
    });
  },
  CHANGE_PASSWORD_AD(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .post(ENDPOINTS.CHANGE_PASSWORD_AD.replace('{id}', data.id), user)
        .then(() => resolve())
        .catch(error => {
          if (
            error.response.status === 401 &&
            error.response.data.message.includes('WILL_NOT_PERFORM')
          )
            reject('Password chosen failed to meet any or all of the requirements stated.');
          reject(error.response.data.message);
        });
    });
  },
  CHANGE_PASSWORD_DB(context, data) {
    return new Promise((resolve, reject) => {
      let user = Object.assign({}, data);
      delete user.id;
      axios
        .post(ENDPOINTS.CHANGE_PASSWORD_DB.replace('{id}', data.id), user)
        .then(() => {
          resolve();
        })
        .catch(error => {
          if (error.response && error.response.status === 401)
            reject(CURRENT_PASSWORD_NOT_VALID_ERROR_MESSAGE);
          reject(CHANGE_PASSWORD_ERROR_MESSAGE);
        });
    });
  },
  VALIDATE_USERNAME({ commit }, username) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.VALIDATE_USERNAME.replace('{username}', username))
        .then(response => {
          commit(VALIDATE_USERNAME, response.data);
          resolve(response.data);
        })
        .catch(() => {
          reject(INVALID_USERNAME_ERROR);
        });
    });
  },
  VALIDATE_SECRET_QUESTIONS(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.VALIDATE_SECRET_QUESTIONS, data)
        .then(response => {
          if (response.data.success) {
            resolve({ success: true });
          } else {
            reject({ success: false, error: INVALID_SECRET_QUESTIONS_ERROR });
          }
        })
        .catch(() => {
          reject({ success: false, error: INVALID_SECRET_QUESTIONS_ERROR });
        });
    });
  },
  UNLOCK_ACCOUNT(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.UNLOCK_ACCOUNT, data)
        .then(response => {
          if (response.data.success) {
            resolve({ success: true });
          } else {
            reject({ success: false, error: INVALID_UNLOCK_ERROR });
          }
        })
        .catch(() => {
          reject({ success: false, error: INVALID_UNLOCK_ERROR });
        });
    });
  },
  RESET_PASSWORD(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.RESET_PASSWORD, data)
        .then(response => {
          if (response.data.success) {
            resolve({ success: true });
          } else {
            reject({ success: false, error: INVALID_RESET_PASSWORD_ERROR });
          }
        })
        .catch(() => {
          reject({ success: false, error: INVALID_RESET_PASSWORD_ERROR });
        });
    });
  },
  ACTION_SET_THEME_SETTINGS({ commit }, data) {
    let userInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
    localStorage.setItem(
      USER_INFO_NAME,
      JSON.stringify({ ...userInfo, theme: { ...userInfo?.theme, ...data } })
    );
    commit('SET_THEME_SETTINGS', data);
  },
  UPDATE_USER_OBJECT({ commit }, newUserInfo) {
    const userInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
    const user = {
      id: newUserInfo._id || newUserInfo.id,
      username: newUserInfo.username,
      accounts: newUserInfo.accounts,
      email: newUserInfo.email,
      name: newUserInfo.fullname,
      acl: newUserInfo.acl,
      veeamServiceAccount: newUserInfo.veeamServiceAccount,
      theme: { ...userInfo.theme },
      sso: { ...userInfo.sso },
      serviceProviderId: userInfo.serviceProviderId,
      masterServiceProviderId: userInfo.masterServiceProviderId,
      phone: newUserInfo.phone,
      managedFilterType: newUserInfo.managedFilterType,
      forceSecurityQuestions: false,
      passwordReset: false,
      isO365Migrator: newUserInfo.isO365Migrator,
      features: newUserInfo.features,
      landingApp: newUserInfo.landingApp,
      ackO365TermsOfUse: newUserInfo.ackO365TermsOfUse,
      hasOrganizations: newUserInfo.hasOrganizations,
      hasJobs: newUserInfo.hasJobs,
      wizardPromptDismissed: newUserInfo.wizardPromptDismissed
    };
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(user));
    commit('SET_USER', user);
  },
  FORGOT_PASSWORD_EMAIL_DB(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.FORGOT_PASSWORD_EMAIL, data)
        .then(() => resolve({ success: true }))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error on the proccess, try again later'
          })
        );
    });
  },
  FORGOT_PASSWORD_CHECK_HASH_DB(context, hash) {
    return new Promise((resolve, reject) => {
      axios
        .get(ENDPOINTS.FORGOT_PASSWORD_CHECK, { params: { hash } })
        .then(response => resolve(response.data))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error while trying to check the link, try again later'
          })
        );
    });
  },
  FORGOT_PASSWORD_CHANGE_DB(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.FORGOT_PASSWORD_CHANGE, data)
        .then(response => resolve(response.data))
        .catch(() =>
          reject({
            success: false,
            message: 'There was an error while trying to change the password, try again later'
          })
        );
    });
  },
  LOGIN_AS({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(ENDPOINTS.LOGIN_AS, data)
        .then(response => {
          let adminUserInfo = JSON.parse(localStorage.getItem(USER_INFO_NAME));
          let adminUserToken = localStorage.getItem(USER_TOKEN_NAME);
          localStorage.setItem('adminUserInfo', JSON.stringify(adminUserInfo));
          localStorage.setItem('adminUserToken', adminUserToken);
          const processedLoginResponse = processLoginResponse(response);
          commit(LOG_IN, processedLoginResponse);
          commit('SET_IS_LOGGED_IN_AS', true);
          resolve(processedLoginResponse.user);
        })
        .catch(() => reject(`Error while logging in`));
    });
  },
  EXIT_FROM_LOGGED_IN_AS({ commit }) {
    let adminUserInfo = JSON.parse(localStorage.getItem('adminUserInfo'));
    let adminUserToken = localStorage.getItem('adminUserToken');
    localStorage.removeItem('adminUserInfo');
    localStorage.removeItem('adminUserToken');
    localStorage.setItem(USER_INFO_NAME, JSON.stringify(adminUserInfo));
    localStorage.setItem(USER_TOKEN_NAME, adminUserToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${adminUserToken}`;
    commit(LOG_IN, { user: adminUserInfo, token: adminUserToken });
    commit('SET_IS_LOGGED_IN_AS', false);
    router.push({ name: localStorage.getItem('previousAdminPage') });
    localStorage.removeItem('previousAdminPage');
  }
};

// getters
const getters = {
  isAuthenticated: state => !!state.token,
  loggedInUser: state => state.user,
  getProfileInfo: state => state.profile,
  getUnlockUser: state => state.unlock,
  getAccounts: state => state.user.accounts,
  getThemeSettings: state => state.user.theme,
  getIsLoggedInAs: state => state.isLoggedInAs,
  getWizardPromptDismissed: state => state.wizardPromptDismissed
};

// mutations
const mutations = {
  [LOG_IN](state, data) {
    const { token, user } = data;
    state.token = token;
    state.user = user;
  },
  [LOG_OUT](state) {
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  [GET_USER](state, data) {
    state.profile = data;
  },
  [UPDATE_USER](state, data) {
    state.profile = JSON.parse(JSON.stringify(data));
  },
  [VALIDATE_USERNAME](state, data) {
    state.unlock = JSON.parse(JSON.stringify(data));
  },
  [SET_ZERTO_SESSION_ID](state, response) {
    state.user.sso.zerto.sessionId = response.data.success;
  },
  SET_THEME_SETTINGS(state, data) {
    state.user = { ...state.user, theme: { ...state.user.theme, ...data } };
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_IS_LOGGED_IN_AS(state, data) {
    state.isLoggedInAs = data;
  },
  [SET_WIZARD_PROMPT_DISMISSED](state, data) {
    localStorage.setItem('wizardPromptDismissed', data);
    state.wizardPromptDismissed = data;
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};
