export const TABLE_FILTERS = {
  DATE: 'DATE',
  DATE_RANGE: 'DATE_RANGE',
  TEXT: 'TEXT'
};

export const DB_COMPLETE_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const DB_DATETIME_FORMAT_HH_MM_SS = 'YYYY-MM-DDTHH:mm:ssZ';
export const DB_COMPLETE_DATE_FORMAT = 'YYYY-MM-DD';

export const SCHEDULE_OPTIONS = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Continuously', value: 'Continuously' },
  { label: 'Periodically', value: 'Periodically' },
  { label: 'Chained', value: 'Chained' },
  { label: 'Disabled', value: 'Disabled' }
];

export const JOB_STATUS_OPTIONS = [
  { label: 'Running', value: 'Running' },
  { label: 'Success', value: 'Success' },
  { label: 'Warning', value: 'Warning' },
  { label: 'Failed', value: 'Failed' }
];

export const JOB_SOURCE = {
  MANAGED_SERVERS: 'managed-servers',
  MONITORED_AGENTS: 'monitored-agents',
  MANAGED_AGENTS: 'managed-agents',
  OFFICE_365: 'office365',
  PUBLIC_CLOUD_VMS: 'public-cloud-vms',
  FILE_SHARE_JOBS: 'file-share-jobs'
};

export const COLORS = {
  SUCCESS: '#7ED321',
  WARNING: '#E6E600',
  FAILED: '#FF151F',
  RUNNING: '#007AB6',
  ANOMALY: '#FDB046'
};

export const CONNECTWISE_LINK = '?routeTo=ServiceFV&srRecID=';
export const AUTOTASK_LINK = 'https://ww2.autotask.net/Mvc/ServiceDesk/TicketDetail.mvc?ticketId=';

export const REPORTS = {
  calendarReportFull: {
    prettyName: 'All Customers Report (Summary & Detail)',
    description: 'For Service Provider Internal Use Only!',
    templateName: 'calendarReportFull'
  },
  calendarReportBasic: {
    prettyName: 'All Customers Calendar View Report',
    description: 'For Service Provider Internal Use Only!',
    templateName: 'calendarReportBasic'
  },
  calendarReportCompanyFull: {
    prettyName: 'Customer Report (Summary & Detail)',
    description: 'Send status reports to individual companies',
    templateName: 'calendarReportCompanyFull'
  },
  calendarReportSummary: {
    prettyName: 'All Customers Report (Summary)',
    description: 'For Service Provider Internal Use Only!',
    templateName: 'calendarReportSummary'
  },
  sspCalendarReportSummary: {
    prettyName: 'All MSP Customers Report (Summary)',
    description: 'For MSP Use Only!',
    templateName: 'sspCalendarReportSummary'
  },
  calendarReportCompanySummary: {
    prettyName: 'Customer Report (Summary)',
    description: 'Send status reports to individual companies',
    templateName: 'calendarReportCompanySummary'
  },
  detailReport: {
    prettyName: 'Job Detail Report',
    description: 'Send status reports',
    templateName: 'detailReport'
  },
  licenseStorageReport: {
    prettyName: 'Licensing & Repositories Report',
    description: 'Send licensing & repositories reports',
    templateName: 'licenseStorageReport'
  },
  multiCustomerReportSummary: {
    prettyName: 'Multi-Customer Report (Summary)',
    description: 'Send status reports from selected companies (Up to 30)',
    templateName: 'multiCustomerReportSummary'
  },
  multiCustomerReportDetails: {
    prettyName: 'Multi-Customer Report (Details)',
    description: 'Send status reports from selected companies (Up to 30)',
    templateName: 'multiCustomerReportDetails'
  },
};

export const MONITORING = 'monitoring';
export const O365 = 'o365';
export const ADMIN = 'admin';

export const TABLE_ACTIONS = {
  REMOVE: {
    label: 'Remove',
    key: 'remove',
    icon: 'tim-icons icon-trash-simple'
  },
  EDIT: {
    label: 'Edit',
    key: 'edit',
    icon: 'tim-icons icon-pencil'
  }
};

export const USER_ROLES = {
  O365_ADMIN: 'o365_admin',
  SUPER_ADMIN: 'super-admin'
};

export const LOCAL_STORAGE_ITEMS = {
  O365_ORG_NAMES: 'o365OrgNames'
};

export const JOB_STATUS = {
  SUCCESS: 'Success',
  WARNING: 'Warning',
  FAILED: 'Failed',
  RUNNING: 'Running'
};

export const VMO365_SP = { ID: 16 };
export const MCAST_SP = { ID: 1 };

export const SUBSCRIPTION_LEVEL = {
  FREE: 'free',
  BASIC: 'basic',
  ADVANCED: 'advanced',
  BUSINESS_CRITICAL: 'businessCritical',
  LICENSE_STORAGE: 'licenseStorage',
  ACH: 'ACH'
};

export const SUBSCRIPTION_LEVEL_LABEL = {
  [SUBSCRIPTION_LEVEL.FREE]: 'Free',
  [SUBSCRIPTION_LEVEL.BASIC]: 'Basic',
  [SUBSCRIPTION_LEVEL.ADVANCED]: 'Advanced',
  [SUBSCRIPTION_LEVEL.BUSINESS_CRITICAL]: 'Business Critical',
  [SUBSCRIPTION_LEVEL.LICENSE_STORAGE]: 'License & Storage',
  [SUBSCRIPTION_LEVEL.ACH]: 'ACH'
};

export const SUBSCRIPTION_OPTIONS = (serviceProvider, user) => {
  const subscriptionLevels = serviceProvider.features.office365.selfRegistration.serviceLevels;
  let subscriptionOptions = subscriptionLevels.map(subscriptionLevel => ({label: subscriptionLevel.label, value: subscriptionLevel.id}));

  // Filter options based on user role.
  const isSuperAdmin = user?.acl?.some(acl => acl.role === "super-admin");
  if (!isSuperAdmin) {
    subscriptionOptions = subscriptionOptions.filter(subscriptionOption => ![SUBSCRIPTION_LEVEL.ACH, SUBSCRIPTION_LEVEL.LICENSE_STORAGE].includes(subscriptionOption.value));
  }

  return subscriptionOptions;
}

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  PAYMENT_FAILED: 'payment_failed',
  DISABLED: 'disabled'
}

export const RESTORE_SESSION_TYPE = {
  VEX: 'Vex',
  VEOD: 'Veod',
  VESP: 'Vesp',
  VET: 'Vet'
};

export const OUTLOOK_ITEMS_TYPE = {
  MAILBOX: 'mailbox',
  FOLDER: 'folder',
  ITEM: 'item'
};

export const ONEDRIVE_ITEMS_TYPE = {
  ONEDRIVE: 'onedrive',
  FOLDER: 'folder',
  DOCUMENT: 'document'
};

export const TEAMS_ITEMS_TYPE = {
  TEAM: 'team',
  CHANNEL: 'channel',
  POST: 'post',
  FILE: 'file',
  TAB: 'tab'
};

export const SHAREPOINT_ITEMS_TYPE = {
  SITE: 'site',
  FOLDER: 'folder',
  ITEM: 'item',
  LIBRARY: 'library',
  LIST: 'list',
  DOCUMENT: 'document'
};

export const YEARLY_RETENTION_POLICY_OPTIONS = [
  { label: '1 Year', value: 'Year1' },
  { label: '2 Years', value: 'Years2' },
  { label: '3 Years', value: 'Years3' },
  { label: '5 Years', value: 'Years5' },
  { label: '7 Years', value: 'Years7' },
  { label: '10 Years', value: 'Years10' },
  { label: '25 Years', value: 'Years25' },
  { label: 'Keep forever', value: 'Keep' }
];
